<template>
    <div>
        <Loader v-if="optionsLoading" />
        <form
            class="form"
            novalidate="novalidate"
            id="st_modal_application_type_form"
        >
            <div class="row pb-6">
                <div class="col-md-6">
                    <div class="form-group">
                        <label>
                            {{ fields.name.label }} *
                        </label>
                        <st-input-text
                            :ref="fields.name.name"
                            :name="fields.name.name"
                            v-model="model[fields.name.name]"
                        />
                    </div>
                    <div class="form-group">
                        <label>
                            {{ fields.description.label }}
                        </label>
                        <b-textarea
                            :ref="fields.description.name"
                            :name="fields.description.name"
                            v-model="model[fields.description.name]"
                        ></b-textarea>
                    </div>
                    <div class="form-group">
                        <label>
                            {{ fields.status.label }} *
                        </label>
                        <b-form-select
                            v-model="model[fields.status.name]"
                            :options="fields.status.options"
                            :ref="fields.status.name"
                            :name="fields.status.name">
                            <template #first>
                                <b-form-select-option
                                    :value="null"
                                    disabled
                                >
                                    {{ $t("GENERAL.SELECT_PLACEHOLDER")}}
                                </b-form-select-option>
                            </template>
                        </b-form-select>
                    </div>
                    <div class="form-group">
                        <label>
                            {{ fields.tax_category_id.label }} *
                        </label>
                        <b-form-select
                            v-model="model[fields.tax_category_id.name]"
                            :options="fields.tax_category_id.options"
                            :ref="fields.tax_category_id.name"
                            :name="fields.tax_category_id.name"
                        >
                            <template #first>
                                <b-form-select-option
                                    :value="null"
                                    disabled
                                >
                                    {{ $t("GENERAL.SELECT_PLACEHOLDER") }}
                                </b-form-select-option>
                            </template>
                        </b-form-select>
                    </div>
                    <b-form-row>
                        <b-col>
                            <div class="form-group">
                                <b-form-checkbox
                                    id="apply_discount"
                                    v-model="applyDiscount"
                                    name="apply_discount"
                                >
                                    {{ $t("APPLICATION_TYPE.PLACEHOLDERS.APPLY_DISCOUNT") }}
                                </b-form-checkbox>
                            </div>
                        </b-col>
                        <b-col v-show="applyDiscount">
                            <div class="form-group">
                                <st-input-text
                                    :ref="fields.rural_percentage_discount.name"
                                    :name="fields.rural_percentage_discount.name"
                                    v-model="model[fields.rural_percentage_discount.name]"
                                />
                            </div>
                        </b-col>
                    </b-form-row>
                    <div class="form-group">
                        <label>
                            {{ fields.workflow_id.label }} *
                        </label>
                        <b-form-select
                            v-model="model[fields.workflow_id.name]"
                            :options="fields.workflow_id.options"
                            :ref="fields.workflow_id.name"
                            :name="fields.workflow_id.name"
                        >
                            <template #first>
                                <b-form-select-option
                                    :value="null"
                                    disabled
                                >
                                    {{ $t("GENERAL.SELECT_PLACEHOLDER") }}
                                </b-form-select-option>
                            </template>
                        </b-form-select>
                    </div>
                    <div class="form-group">
                        <label>
                            {{ fields.tenant_id.label }}
                        </label>
                        <b-form-select
                            v-model="model[fields.tenant_id.name]"
                            :options="fields.tenant_id.options"
                            :ref="fields.tenant_id.name"
                            :name="fields.tenant_id.name"
                        >
                            <template #first>
                                <b-form-select-option
                                    :value="null"
                                    disabled
                                >
                                    {{ $t("GENERAL.SELECT_PLACEHOLDER") }}
                                </b-form-select-option>
                            </template>
                        </b-form-select>
                    </div>
                    <div class="form-group">
                        <label>
                            {{ fields.remaining_period.label }} *
                        </label>
                        <st-input-text
                            :ref="fields.remaining_period.name"
                            :name="fields.remaining_period.name"
                            v-model="model[fields.remaining_period.name]"
                            type="number"
                        />
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label>
                            {{ fields.related_application_type_ids.label }}
                        </label>
                        <b-form-select
                            v-model="model[fields.related_application_type_ids.name]"
                            :options="filterRelatedAppOptions"
                            :ref="fields.related_application_type_ids.name"
                            :name="fields.related_application_type_ids.name"
                            multiple
                            :select-size="3"
                            @change="checkReference">
                            <template #first>
                                <b-form-select-option value="">{{$t("APPLICATION_TYPE.PLACEHOLDERS.NO_REF_DOC")}}
                                </b-form-select-option>
                            </template>
                        </b-form-select>
                    </div>
                    <div class="form-group">
                        <label>
                            {{ fields.form_citizen_id.label }} *
                        </label>
                        <b-form-select
                            v-model="model[fields.form_citizen_id.name]"
                            :options="fields.form_citizen_id.options"
                            :ref="fields.form_citizen_id.name"
                            :name="fields.form_citizen_id.name"
                        >
                            <template #first>
                                <b-form-select-option
                                    :value="null"
                                    disabled
                                >
                                    {{ $t("GENERAL.SELECT_PLACEHOLDER") }}
                                </b-form-select-option>
                            </template>
                        </b-form-select>
                    </div>
                    <div class="form-group">
                        <label>
                            {{ fields.form_staff_id.label }} *
                        </label>
                        <b-form-select
                            v-model="model[fields.form_staff_id.name]"
                            :options="fields.form_staff_id.options"
                            :ref="fields.form_staff_id.name"
                            :name="fields.form_staff_id.name"
                        >
                            <template #first>
                                <b-form-select-option
                                    :value="null"
                                    disabled
                                >
                                    {{ $t("GENERAL.SELECT_PLACEHOLDER") }}
                                </b-form-select-option>
                            </template>
                        </b-form-select>
                    </div>
                    <div class="form-group">
                        <label>
                            {{ fields.reject_form_staff_id.label }} *
                        </label>
                        <b-form-select
                            v-model="model[fields.reject_form_staff_id.name]"
                            :options="fields.reject_form_staff_id.options"
                            :ref="fields.reject_form_staff_id.name"
                            :name="fields.reject_form_staff_id.name"
                        >
                            <template #first>
                                <b-form-select-option
                                    :value="null"
                                    disabled
                                >
                                    {{ $t("GENERAL.SELECT_PLACEHOLDER") }}
                                </b-form-select-option>
                            </template>
                        </b-form-select>
                    </div>
                    <div class="form-group">
                        <label>
                            {{ fields.archive_document_type_code.label }} *
                        </label>
                        <b-form-select
                            v-model="model[fields.archive_document_type_code.name]"
                            :options="arhiveDocumentTypeList"
                            :ref="fields.archive_document_type_code.name"
                            :name="fields.archive_document_type_code.name"
                        >
                            <template #first>
                                <b-form-select-option
                                    :value="null"
                                    disabled
                                >
                                    {{ $t("GENERAL.SELECT_PLACEHOLDER") }}
                                </b-form-select-option>
                            </template>
                        </b-form-select>
                    </div>
                    <div class="form-group">
                        <label>
                            {{ fields.application_document_series_type_id.label }} *
                        </label>
                        <b-form-select
                            v-model=" model[fields.application_document_series_type_id.name]"
                            :options="fields.application_document_series_type_id.options"
                            :ref="fields.application_document_series_type_id.name"
                            :name="fields.application_document_series_type_id.name"
                        >
                            <template #first>
                                <b-form-select-option
                                    :value="null"
                                    disabled
                                >
                                    {{ $t("GENERAL.SELECT_PLACEHOLDER") }}
                                </b-form-select-option>
                            </template>
                        </b-form-select>
                    </div>
                    <div class="form-group">
                        <label>
                            {{ fields.document_series_type_id.label }} *
                        </label>
                        <b-form-select
                            v-model="model[fields.document_series_type_id.name]"
                            :options="fields.document_series_type_id.options"
                            :ref="fields.document_series_type_id.name"
                            :name="fields.document_series_type_id.name"

                        >
                            <template #first>
                                <b-form-select-option
                                    :value="null"
                                    disabled
                                >
                                    {{ $t("GENERAL.SELECT_PLACEHOLDER") }}
                                </b-form-select-option>
                            </template>
                        </b-form-select>
                    </div>
                    <div v-if="editMode" class="form-group">
                        <label>
                            {{ fields.application_document_template_id.label }}
                        </label>
                        <b-form-select
                            v-model=" model[fields.application_document_template_id.name]"
                            :options="fields.application_document_template_id.options"
                            :ref="fields.application_document_template_id.name"
                            :name="fields.application_document_template_id.name"
                        >
                            <template #first>
                                <b-form-select-option
                                    :value="null"
                                    disabled
                                >
                                    {{ $t("GENERAL.SELECT_PLACEHOLDER") }}
                                </b-form-select-option>
                            </template>
                        </b-form-select>
                    </div>

                    <div v-if="editMode" class="form-group">
                        <label>
                            {{ fields.document_template_id.label }}
                        </label>
                        <b-form-select
                            v-model="model[fields.document_template_id.name]"
                            :options="fields.document_template_id.options"
                            :ref="fields.document_template_id.name"
                            :name="fields.document_template_id.name"

                        >
                            <template #first>
                                <b-form-select-option
                                    :value="null"
                                    disabled
                                >
                                    {{ $t("GENERAL.SELECT_PLACEHOLDER") }}
                                </b-form-select-option>
                            </template>
                        </b-form-select>
                    </div>

                    <div v-if="editMode" class="form-group">
                        <label>
                            {{ fields.reject_document_template_id.label }}
                        </label>
                        <b-form-select
                            v-model="model[fields.reject_document_template_id.name]"
                            :options="fields.reject_document_template_id.options"
                            :ref="fields.reject_document_template_id.name"
                            :name="fields.reject_document_template_id.name"
                        >
                            <template #first>
                                <b-form-select-option
                                    :value="null"
                                    disabled
                                >
                                    {{ $t("GENERAL.SELECT_PLACEHOLDER") }}
                                </b-form-select-option>
                            </template>
                        </b-form-select>
                    </div>

                    <div class="form-group">
                        <b-form-checkbox
                            v-model=" model[fields.is_auto.name]"
                            :ref="fields.is_auto.name"
                            :name="fields.is_auto.name"
                            @change="checkReference"
                        >
                            {{ $t("APPLICATION_TYPE.FIELDS.IS_AUTO") }}
                        </b-form-checkbox>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import { FormSchema } from "@/shared/form/form-schema";
import { createFormValidation } from "@/shared/utils/create-form-validation";
import { ApplicationTypeModel } from "@/modules/application-type/application-type-model";
import Message from '@/shared/message/message';

const { fields } = ApplicationTypeModel;

const formSchema = new FormSchema([
    fields.id,
    fields.name,
    fields.description,
    fields.status,
    fields.form_citizen_id,
    fields.form_staff_id,
    fields.reject_form_staff_id,
    fields.document_template_id,
    fields.reject_document_template_id,
    fields.application_document_template_id,
    fields.document_series_type_id,
    fields.application_document_series_type_id,
    fields.tax_category_id,
    fields.related_application_type_ids,
    fields.workflow_id,
    fields.rural_percentage_discount,
    fields.archive_document_type_code,
    fields.tenant_id,
    fields.is_auto,
    fields.remaining_period
]);

import { mapActions, mapGetters } from "vuex";

export default {
    name: "ApplicationTypeForm",
    props: {
        editMode: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            rules: formSchema.rules(),
            model: null,
            optionsLoading: false,
            applyDiscount: false,
            arhiveDocumentTypeList: []
        };
    },
    computed: {
        ...mapGetters({
            record: "applicationType/form/record",
            filters: "applicationType/list/filters",
        }),
        headerText() {
            return this.editMode
                ? this.$t("APPLICATION_TYPE.FORM.EDIT_TITLE")
                : this.$t("APPLICATION_TYPE.FORM.ADD_TITLE");
        },
        fields() {
            return fields;
        },
        filterRelatedAppOptions() {
            if (this.editMode) {
                return fields.related_application_type_ids.options.filter((elem) => elem.value !== this.record.id);
            } else {
                return fields.related_application_type_ids.options;
            }
        },
    },
    methods: {
        ...mapActions({
            doCreate: "applicationType/form/create",
            doUpdate: "applicationType/form/update",
            doFetch: "applicationType/list/doFetch",
            doGetAllArchiveDocumentsTypes: "archive/document/getAllArchiveDocumentsTypes",
        }),
        hide() {
            this.$emit('cancelForm');
        },
        doReset() {
            this.model = formSchema.initialValues(this.record);
        },
        show() {
            this.$refs['modal'].show();
        },
        async doSubmit() {
            const validate = await this.fv.validate();
            if (validate === "Valid") {
                const selectedWorkflow = this.fields.workflow_id.options
                    .find(workflow => workflow.value === this.model.workflow_id);

                const { id, ...values } = formSchema.cast(this.model);
                values.workflow_name = selectedWorkflow.text;
                values.application_type_scope = 'tenant';

                if (this.model.document_template_id) {
                    const selectedDocumentTemplate = this.fields.document_template_id.options
                        .find(documentTemplate => documentTemplate.value === this.model.document_template_id);
                    values.document_template_name = selectedDocumentTemplate.text;
                }

                if (this.model.reject_document_template_id) {
                    const rejectedDocumentTemplate = this.fields.reject_document_template_id.options
                        .find(documentTemplate => documentTemplate.value === this.model.reject_document_template_id);
                    values.reject_document_template_name = rejectedDocumentTemplate.text;
                }

                if (this.model.tenant_id) {
                    const selectedTenant = this.fields.tenant_id.options
                        .find(option => option.value === this.model.tenant_id);
                    values.tenant_name = selectedTenant.text;
                }
                if (!this.applyDiscount) {
                    values.rural_percentage_discount = null;
                }
                if (this.editMode) {
                    this.doUpdate({ id, values }).then(() => this.hide());
                } else {
                    this.doCreate(values).then(() => {
                        this.hide();
                        this.doFetch(this.filters);
                    });
                }
            }
        },
        checkReference(){
            const refDoc = this.model.related_application_type_ids.filter((el) => el !== '');
            if (this.model.is_auto && refDoc?.length) {
                Message.warning('APPLICATION_TYPE.MESSAGES.IS_AUTO');
                this.model.is_auto = false;
            }
        }
    },
    created() {
        this.model = formSchema.initialValues(this.record);
        if (!this.record || !this.record?.related_application_type_ids) {
            this.model.related_application_type_ids = [""];
        }
        this.applyDiscount = !!this.record?.rural_percentage_discount;
    },
    async mounted() {
        this.fv = createFormValidation(
            "st_modal_application_type_form",
            this.rules
        );

        if (!this.applyDiscount) {
            this.fv.disableValidator(fields.rural_percentage_discount.name);
        }

        this.optionsLoading = true;

        for (let key in fields) {
            if (fields[key]?.fetchFn ) {
                await fields[key].updateOptions();
            }
        }

        this.doGetAllArchiveDocumentsTypes()
        .then((data) => {
            this.arhiveDocumentTypeList = data.map(doc => {
                return {
                    value: doc.code,
                    text: doc.name,
                }
            });
        })
        .finally(() => {
            this.optionsLoading = false;
        })
    },
    watch: {
        applyDiscount: {
            immediate: false,
            handler(value) {
                if (this.fv) {
                    if (!value) {
                        this.fv.disableValidator(fields.rural_percentage_discount.name);
                    } else {
                        this.fv.enableValidator(fields.rural_percentage_discount.name);
                    }
                }
            }
        }
    },
};
</script>
