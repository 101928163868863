import GenericModel from '@/shared/models/generic-model';
import i18n from '@/shared/plugins/vue-i18n';
import StringField from '@/shared/fields/string-field';
import EnumField from '@/shared/fields/enum-field';
import IdField from '@/shared/fields/id-field';
import { FormsField } from '@/modules/forms/forms-field';
import { DocumentTemplateField } from '@/modules/document-template/document-template-field';
import { DocumentSeriesTypeField } from '@/modules/document-series-type/document-series-type-field';
import { TaxCategoryField } from '@/modules/tax-category/tax-category-field';
import { ApplicationTypeField } from '@/modules/application-type/application-type-field';
import { WorkflowsField } from '@/modules/application-type/workflows-field';
import { OrganizationField } from '@/modules/administrator/fields/organisations-field';
import IntegerField from '@/shared/fields/integer-field';
import BooleanField from '@/shared/fields/boolean-field';

const label = (name) => i18n.t(`APPLICATION_TYPE.FIELDS.${name.toUpperCase()}`);
const enumLabel = (name, value) => i18n.t(`APPLICATION_TYPE.ENUMS.${name.toUpperCase()}.${value.toUpperCase()}`);
const placeholder = (name) => i18n.t(`APPLICATION_TYPE.PLACEHOLDERS.${name.toUpperCase()}`);

const fields = {
    id: new IdField('id', 'Id'),
    name: new StringField('name', label('name'), {
        required: true,
    }, { placeholder: placeholder('name') }),
    description: new StringField('description', label('description')),
    status: new EnumField('status', label('status'), [
        { value: 'active', text: enumLabel('status', 'active') },
        { value: 'inactive', text: enumLabel('status', 'inactive') }
    ], {
        required: true
    }, { placeholder: placeholder('status') }),
    form_citizen_id: FormsField.relationToOne(
        'form_citizen_id',
        label('form_citizen'),
        { required: true },
    ),
    form_staff_id: FormsField.relationToOne(
        'form_staff_id',
        label('form_staff'),
        { required: true },
    ),
    reject_form_staff_id: FormsField.relationToOne(
        'reject_form_staff_id',
        label('reject_form_staff'),
        { required: true },
    ),
    document_template_id: DocumentTemplateField.relationToOne(
        'document_template_id',
        label('document_template'),
    ),
    application_document_template_id: DocumentTemplateField.relationToOne(
        'application_document_template_id',
        label('application_document_template'),
    ),
    reject_document_template_id: DocumentTemplateField.relationToOne(
        'reject_document_template_id',
        label('reject_document_template'),
    ),
    document_series_type_id: DocumentSeriesTypeField.relationToOne(
        'document_series_type_id',
        label('document_series_type'),
        { required: true },
    ),
    application_document_series_type_id: DocumentSeriesTypeField.relationToOne(
        'application_document_series_type_id',
        label('application_document_series_type'),
        { required: true },
    ),
    tax_category_id: TaxCategoryField.relationToOne(
        'tax_category_id',
        label('tax_category'),
        { required: true },
    ),
    related_application_type_ids: ApplicationTypeField.relationToOne(
        'related_application_type_ids',
        label('application_type_ref'),
        {},
    ),
    workflow_id: WorkflowsField.relationToOne(
        'workflow_id',
        label('workflow'),
        { required: true },
    ),
    rural_percentage_discount: new IntegerField(
        'rural_percentage_discount',
        '',
        { required: true, min: 1, max: 100 },
    ),
    archive_document_type_code: new StringField(
        'archive_document_type_code',
        label('archive_document_type_code'), { required: true }
    ),
    tenant_id: OrganizationField.relationToOne(
        'tenant_id',
        label('tenant_id'),
        {},
        { placeholder: placeholder('tenant_id') },
    ),
    is_auto: new BooleanField('is_auto', label('is_auto')),
    remaining_period: new IntegerField(
        'remaining_period',
        label('remaining_period'),
        { required: true },
    ),
};

export class ApplicationTypeModel extends GenericModel {
    static get fields() {
        return fields;
    }
}
